
$.mask.definitions.q = "[1,2,3,4,5,6,9]",
   $('input[type="tel"]').mask('+7 (q99) 999-99-99');

(function () {
   'use strict';
   window.addEventListener('load', function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
         form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
               event.preventDefault();
               event.stopPropagation();
            }
            form.classList.add('was-validated');
         }, false);
      });
   }, false);
})();


// slick 1
$('.stories-for').slick({
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   fade: true,
   adaptiveHeight: true,
   swipe: false,
   asNavFor: '.stories-nav'
});

$('.stories-nav').slick({
   slidesToShow: 4,
   slidesToScroll: 1,
   asNavFor: '.stories-for',
   //centerMode: true,
   focusOnSelect: true,
   //vertical: true,
   arrows: false,
   swipe: true,
   responsive: [
      {
         breakpoint: 991,
         settings: {
            slidesToShow: 3,
         }
      },
      {
         breakpoint: 767,
         settings: {
            slidesToShow: 2,
         }
      },
      {
         breakpoint: 500,
         settings: {
            slidesToShow: 1,
            variableWidth: true
         }
      },
   ]
});
// slick 1 end

// quiz start
$(".quiz .variant").click(function () {
   if ($(this).find($("input:checked")).length > 0) {
      $(this).closest(".step.active").next().addClass("active"),
         $(this).closest(".step.active").removeClass("active");
      var
         s = $('.quiz').offset().top - 10;
      $("body,html").animate({
         scrollTop: s
      }, 1e3);
      var i = $(this).closest(".quiz").find(".prg1 .line").attr("data-left");
      $(this).closest(".quiz").find(".prg1 .line").css("left", -1 * (parseInt(i) - 19) + "%"),
         $(this).closest(".quiz").find(".prg1 .line").attr("data-left", parseInt(i) - 19);
      var a = $(".current-step").html();
      $(".current-step").html(a - 1);
      if (a == 2) {
         $('#progress-text-1').html('Все готово. <strong>Остался последний шаг</strong>');
         //$('#progress-text').addClass('final-step');
         $(this).closest(".quiz").addClass("quiz-finish");
      }
      if (a == 2) { $('.rp-rule__span').html('вопрос') } else
         if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
            $('.rp-rule__span').html('вопросов')
         }
   }
});


$(".quiz-modal .variant").click(function () {
   if ($(this).find($("input:checked")).length > 0) {
      $(this).closest(".step.active").next().addClass("active"),
         $(this).closest(".step.active").removeClass("active");
      var i = $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left");
      $(this).closest(".quiz-modal").find(".prg1 .line").css("left", -1 * (parseInt(i) - 19) + "%"),
         $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left", parseInt(i) - 19);
      var a = $(".current-step2").html();
      $(".current-step2").html(a - 1);
      if (a == 2) {
         $('#progress-text-2').html('Все готово. <strong>Остался последний шаг</strong>');
         //$('#progress-text').addClass('final-step');
         $(this).closest(".quiz-modal").addClass("quiz-finish");
      }
      if (a == 2) { $('.rp-rule__span').html('вопрос') } else
         if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
            $('.rp-rule__span').html('вопросов')
         }
   }
});

$('.quiz .quiz-skip').click(function () {
   $(this).closest(".step.active").next().addClass("active"),
      $(this).closest(".step.active").removeClass("active");
   var
      s = $('.quiz').offset().top - 10;
   $("body,html").animate({
      scrollTop: s
   }, 1e3);
   var i = $(this).closest(".quiz").find(".prg1 .line").attr("data-left");
   $(this).closest(".quiz").find(".prg1 .line").css("left", -1 * (parseInt(i) - 19) + "%"),
      $(this).closest(".quiz").find(".prg1 .line").attr("data-left", parseInt(i) - 19);
   var a = $(".current-step").html();
   $(".current-step").html(a - 1);
   if (a == 2) {
      $('#progress-text-1').html('Все готово. <strong>Остался последний шаг</strong>');
      //$('#progress-text').addClass('final-step');
      $(this).closest(".quiz").addClass("quiz-finish");
   }
   if (a == 2) { $('.rp-rule__span').html('вопрос') } else
      if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
         $('.rp-rule__span').html('вопросов')
      }

});
$('.quiz-modal .quiz-skip').click(function () {
   $(this).closest(".step.active").next().addClass("active"),
      $(this).closest(".step.active").removeClass("active");
   var i = $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left");
   $(this).closest(".quiz-modal").find(".prg1 .line").css("left", -1 * (parseInt(i) - 19) + "%"),
      $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left", parseInt(i) - 19);
   var a = $(".current-step2").html();
   $(".current-step2").html(a - 1);
   if (a == 2) {
      $('#progress-text-2').html('Все готово. <strong>Остался последний шаг</strong>');
      //$('#progress-text').addClass('final-step');
      $(this).closest(".quiz-modal").addClass("quiz-finish");
   }
   if (a == 2) { $('.rp-rule__span').html('вопрос') } else
      if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
         $('.rp-rule__span').html('вопросов')
      }

});
$('.next-step').click(function () {
   $(this).parent().find(".quiz-needs-select").addClass("visible-block")
});

//quiz end

// map
YaMapsShown = false;
$(document).ready(function () {
   if ($(window).scrollTop() + $(window).height() > $(document).height() - 3000) {
      showYaMaps();
      YaMapsShown = true;
   }
   $(window).scroll(function () {
      if (!YaMapsShown) {
         if ($(window).scrollTop() + $(window).height() > $(document).height() - 3000) {
            showYaMaps();
            YaMapsShown = true;
         }
      }
   });

});
function showYaMaps() {
   var script = document.createElement("script");
   script.type = "text/javascript";
   script.src = "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ab61dbdc186613cebbb8f1eee4d4b9f251591a47c90ed44bd9f7672b2ae949759&amp;width=100%25&amp;height=100%&amp;lang=ru_RU&amp;scroll=false";
   document.querySelector('#YaMaps').appendChild(script);
};
// map end

var date = new Date();
var hourr = date.getHours();
var week = date.getDay();

if ((week >= 1 && week <= 5 && (hourr < 9 || hourr > 20)) || week == 0 || (week == 6 && (hourr < 10 || hourr > 19))) {
   $('.contacts__working').css('display', 'none');
}


